@media only screen and (max-width: 600px) {
  .col.champ-text {
    font-size: 12px;
  }
  .container.match-container{
    width: min-content;
  }
  .match-col-team {
    width: max-content;
  }
  .champ-text {
    width: max-content;
    text-align: left;
  }
  .champ-kda {
    font-size: 12px;
    width: max-content;
    display: none;
  }
  .match-col-head  {
    margin-left: 120px;
  }
  .row.match-row {
    width: fit-content;
    text-align: center;
  }
  .match-map {
    margin-right: 50px;
  }
  .match-type {
    display: flex;
  }
}

.a:hover {
  text-decoration: underline;
}

.bg {
  height: 100vh;
  min-height: 100vh;
  overflow: auto;
  background-image: url("./assets/img/pattern.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position:static;
}
.container.form-container {
  border: 3px groove rgba(168, 151, 151, 0.25);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 100px;
  max-width: 500px;
  margin-top: 2%;
}

.form-header {
  text-align: center;
  padding: 20px;
}

.form-control.form-text {
  width: 50%;
  margin: 0 auto;
}

.form-button {
  margin: auto
}

.btn.btn-primary.btn-sm{
  margin: auto;
  margin-top: 10px;
  width: 60px;
}

.container.match-container{
  border: 3px groove rgba(168, 151, 151, 0.25);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 40px;
  margin-top: 5%;
}

.row.match-row{
  padding: 15px 0px 15px 0px;
  border: 3px groove rgba(168, 151, 151, 0.25);
}
.col.match-col-head{
  margin-right: 30px;
}
.match-map {
  font-size: 28px;
  text-align: center;
  margin-top: 15px;
  font-weight: 600;

}
.match-type {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.container.no-match{
  align-items: center;
  min-width: 100px;
  max-width: 800px;
}

.no-match-alert{
  margin-top: 20px;
  text-align: center;
}

.champ-icon {
  width: 32px;
  height: 32px;
}

.col.char-icon {
  max-width: 32px;
  max-height: 32px;
  padding: 0px;
}


.tooltip-container {
  align-items: center;
  min-width: 100px;
  max-width: 800px;
}

.alert.tooltip {
  margin-top: 20px; 
  text-align: center;
  font-size: 18px;
  background-color: rgba(251, 251, 251, 1.0);
  opacity: .98 !important;
  color: black;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.champ-text {
  color: "black";
  text-decoration: none;
}
.champ-text:hover {
  text-decoration: underline;
}

.row.player-row {
  padding: 3px 5px 3px 5px
}

.col.match-col-team {
  border: 6px groove rgba(168, 151, 151, 0.25);
}

.champ-kda {
  text-align: end;
}

.match-link{
  text-decoration: none;
}
.match-link:hover{
  text-decoration: underline;
}